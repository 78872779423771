import * as React from "react"

const Moody = () => {
    return (
        <div>
            hello world!
        </div>
    )
}

export default Moody;
